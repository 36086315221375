import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "../../../../shared/components/table/Table";
import * as React from "react";

import {
  ColumnDef,
  ColumnSort,
  Row,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { cx } from "../../../../shared/utils";
import { TablePaginationServerSide } from "../../../../shared/components/table/TablePaginationServerSide";
import { PAGE_SIZE } from "../data";
import { Button } from "../../../../shared/components/button/Button";
import { NameSearchbar } from "./NameSearchbar";
import {
  actionDueOptions,
  conversationValueOptions,
  lastReplyExternalCreatedAtDaysOptions,
  outreachStatusOptions,
} from "../data/data";
import {
  DataTableCheckboxFilter,
  SelectFilter,
} from "../../../../shared/components/table/DataTableFilter";
import { Switch } from "../../../../shared/components/switch/Switch";
import { Label } from "../../../../shared/components/label/Label";

interface DataTableProps<TData> {
  columns: ColumnDef<TData>[];
  data: TData[];
  onRowClick?: (row: Row<TData>) => void;
  totalRecords: number;
  offset: number;
  saleActionStatusesOptions: {
    id: number;
    name: string;
  }[];
  workspaceOptions: {
    id: number;
    name: string;
  }[];
  onWorkspaceChange: (selectedIds: number[]) => void;
  onSaleActionStatusChange: (selectedIds: number[]) => void;
  setSorting: React.Dispatch<React.SetStateAction<ColumnSort[]>>;
  sorting: ColumnSort[];
  searchTerm: string;
  handleSearchChange: (needle: string) => void;
  onPaginationChange: ({ offset, limit }: { offset: number; limit: number }) => void;
  onLastReplyFilterChange: (value: number) => void;
  onOutreachStatusFilterChange: (value: number) => void;
  onConversationValueFilterChange: (value: number) => void;
  handleActionDueDateChange: (value: number) => void;
  isFiltered: boolean;
  triggerFilter: boolean;
  resetFilters: () => void;
  handleUnreadOnlyChange: () => void;
  unreadOnly: boolean;
}

export function DataTable<TData>({
  columns,
  data,
  onRowClick,
  totalRecords,
  saleActionStatusesOptions,
  offset,
  setSorting,
  sorting,
  onPaginationChange,
  onSaleActionStatusChange,
  searchTerm,
  handleSearchChange,
  isFiltered,
  triggerFilter,
  onWorkspaceChange,
  workspaceOptions,
  onOutreachStatusFilterChange,
  handleActionDueDateChange,
  onLastReplyFilterChange,
  resetFilters,
  onConversationValueFilterChange,
  handleUnreadOnlyChange,
  unreadOnly,
}: DataTableProps<TData>) {
  const pageSize = PAGE_SIZE;
  const [rowSelection, setRowSelection] = React.useState({});
  const table = useReactTable({
    data,
    columns,
    manualPagination: true,
    manualSorting: true,
    state: {
      rowSelection,
      sorting,
    },
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: pageSize,
      },
    },
    enableRowSelection: true,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });

  const saleActionOptions: { value: number; label: string }[] = saleActionStatusesOptions.map(
    saleActionStatus => ({
      value: saleActionStatus.id,
      label: saleActionStatus.name,
    })
  );

  const onSearchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchChange(event.target.value);
  };
  const workspaceValueOptions: { value: number; label: string }[] = workspaceOptions.map(
    workspace => ({
      value: workspace.id,
      label: workspace.name,
    })
  );

  return (
    <>
      <div className="space-y-3">
        <div className="flex flex-wrap items-center justify-between gap-2 sm:gap-x-6">
          <div className="flex w-full flex-col gap-2 sm:w-fit sm:flex-row sm:items-center">
            <div className="flex items-center gap-3">
              <Switch id="unreadOnly" onClick={handleUnreadOnlyChange} checked={unreadOnly} />
              <Label className="cursor-pointer" htmlFor="unreadOnly">
                Unread only
              </Label>
            </div>
            <DataTableCheckboxFilter
              onSelectionApplied={onSaleActionStatusChange}
              title="Sale Status"
              triggerFilter={triggerFilter}
              options={saleActionOptions}
            />
            <DataTableCheckboxFilter
              onSelectionApplied={onWorkspaceChange}
              title="Workspace"
              triggerFilter={triggerFilter}
              options={workspaceValueOptions}
            />
            <SelectFilter
              title="Last Reply"
              triggerFilter={triggerFilter}
              options={lastReplyExternalCreatedAtDaysOptions}
              onSelectionApplied={onLastReplyFilterChange}
            />
            <SelectFilter
              title="Outreach Status"
              triggerFilter={triggerFilter}
              options={outreachStatusOptions}
              onSelectionApplied={onOutreachStatusFilterChange}
            />
            <SelectFilter
              title="Converation Value"
              triggerFilter={triggerFilter}
              options={conversationValueOptions}
              onSelectionApplied={onConversationValueFilterChange}
            />
            <SelectFilter
              title="Action Due Date"
              triggerFilter={triggerFilter}
              options={actionDueOptions}
              onSelectionApplied={handleActionDueDateChange}
            />
            <NameSearchbar
              type="search"
              placeholder="Search by name"
              value={searchTerm}
              onChange={onSearchNameChange}
              className="w-full sm:max-w-[250px] sm:[&>input]:h-[30px]"
            />
            {isFiltered && (
              <Button
                variant="ghost"
                onClick={resetFilters}
                className="border border-gray-200 px-2 font-semibold text-orange-600 sm:border-none sm:py-1 dark:border-gray-800 dark:text-orange-500"
              >
                Clear filters
              </Button>
            )}
          </div>
          <div className="flex items-center gap-2"></div>
        </div>
        <div className="relative overflow-hidden overflow-x-auto">
          <Table>
            <TableHead>
              {table.getHeaderGroups().map(headerGroup => (
                <TableRow
                  key={headerGroup.id}
                  className=" border-y border-gray-200 dark:border-gray-800"
                >
                  {headerGroup.headers.map(header => (
                    <TableHeaderCell key={header.id} className={cx("whitespace-nowrap py-1")}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHeaderCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map(row => (
                  <TableRow
                    key={row.id}
                    onClick={() => onRowClick?.(row)}
                    // @MAXIME: onClick should trigger Drawer (DataTableRowActions) + same behavior for edit button (left column)
                    // onClick={() => row.toggleSelected(!row.getIsSelected())}
                    className=" group select-none hover:bg-gray-50 hover:dark:bg-gray-900"
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <TableCell
                        key={cell.id}
                        className={cx(
                          row.getIsSelected() ? "bg-gray-50 dark:bg-gray-900" : "",
                          "relative whitespace-nowrap py-2 text-gray-700 first:w-10 dark:text-gray-300"
                        )}
                      >
                        {index === 0 && row.getIsSelected() && (
                          <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-500 dark:bg-blue-500" />
                        )}
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePaginationServerSide
          offset={offset}
          onChangeHandler={onPaginationChange}
          totalRecords={totalRecords}
          table={table}
          pageSize={pageSize}
        />
      </div>
    </>
  );
}
