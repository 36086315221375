import * as yup from "yup";

export const createWorkspacePostcodeDistrictSchema = yup.object().shape({
  isActive: yup.boolean().required("Status is required"),
  postcodeDistrictId: yup.number().required("Postcode District is required"),
});

export type CreateWorkspacePostcodeDistrictSchema = {
  isActive: boolean;
  postcodeDistrictId: number;
};
