import { gql } from "@apollo/client";

export const getWorkspacesPaginated = gql`
  query getWorkspacesPaginated(
    $offset: Int
    $limit: Int
    $workspacesActiveFilter: Int_comparison_exp!
    $orderBy: [workspace_order_by!]
  ) {
    workspace_aggregate(where: { workspace_status_id: $workspacesActiveFilter }) {
      aggregate {
        count
      }
    }
    workspace(
      where: { workspace_status_id: $workspacesActiveFilter }
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      id
      name
      workspace_status_id
    }
  }
`;
