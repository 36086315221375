import { gql } from "@apollo/client";

export const getWorkspacePostcodeDistricts = gql`
  query getWorkspacePostcodeDistricts(
    $orderBy: [workspace_district_order_by!] 
    $workspaceId: Int!
  ) { 
    workspace_district(
      order_by: $orderBy
      where: { workspace_id: { _eq: $workspaceId } }
    ) {
      id
      postcode_district {
        code
        listings_last_fetched_at
      }
      postcode_district_id
      is_active
    }
  }
`;
