import { useQuery } from "@apollo/client";
import { getActiveWorkspacePostcodeDistricts } from "../queries/getActiveWorkspacePostcodeDistricts";
import { GetActiveWorkspacePostcodeDistrictsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";

export type UseActiveWorkspacePostcodeDistricts = ReturnType<typeof useActiveWorkspacePostcodeDistricts>;

export const useActiveWorkspacePostcodeDistricts = ({ workspaceId }: { workspaceId: number }) => {
  const { data, loading, error, refetch } = useQuery<GetActiveWorkspacePostcodeDistrictsQuery>(
    getActiveWorkspacePostcodeDistricts,
    {
      variables: {
        workspaceId,
      },
    }
  );

  return {
    workspaceDistricts:
      data && Array.isArray(data.workspace_district) ? data.workspace_district : [],
    workspaceDistrictsError: error,
    workspaceDistrictsLoading: loading,
    refetchWorkspaceDistricts: refetch,
  };
};
