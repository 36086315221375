import { useQuery } from "@apollo/client";
import { getWorkspacePostcodeDistricts } from "../queries/getWorkspacePostcodeDistricts";
import { GetWorkspacePostcodeDistrictsQuery } from "../../../../infrastructure/graphql/core/generatedTypes";

export type UseWorkspacePostcodeDistricts = ReturnType<typeof useWorkspacePostcodeDistricts>;

export const useWorkspacePostcodeDistricts = ({ workspaceId }: { workspaceId: number }) => {
  const { data, loading, error, refetch } = useQuery<GetWorkspacePostcodeDistrictsQuery>(
    getWorkspacePostcodeDistricts,
    {
      variables: {
        workspaceId,
      },
    }
  );

  return {
    workspaceDistricts:
      data && Array.isArray(data.workspace_district) ? data.workspace_district : [],
    workspaceDistrictsError: error,
    workspaceDistrictsLoading: loading,
    refetchWorkspaceDistricts: refetch,
  };
};
