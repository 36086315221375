import { useParams } from "react-router-dom";
import { Button } from "../../../../shared/components/button/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../shared/components/select/Select";
import { RiAddLine } from "@remixicon/react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../shared/components/dialog/Dialog";
import { Label } from "../../../../shared/components/label/Label";
import ErrorMessage from "../../../../shared/components/errorMessage/ErrorMessage";
import React, { useEffect } from "react";
import { DataTable } from "../components/DataTable";
import { getColumns } from "../components/Columns";
import { Switch } from "../../../../shared/components/switch/Switch";
import { ArrayElement } from "../../../../shared/utils";
import { usePostcodeDistricts } from "../../../../shared/domains/postcodes/graphql/hooks/usePostcodeDistricts";
import { Row } from "@tanstack/react-table";
import {
  UseWorkspacePostcodeDistricts,
  useWorkspacePostcodeDistricts,
} from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspacePostcodeDistricts";
import {
  CreateWorkspacePostcodeDistrictSchema,
  createWorkspacePostcodeDistrictSchema,
} from "../validation";
import { useChangeWorkspaceDistricts } from "../../../../shared/domains/workspaces/REST/changeWorkspacePostcodeDistrictsAPI";

export default function SingleWorkspacePostcodeDistricts() {
  let { workspaceId } = useParams<{ workspaceId: string }>();
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const {
    mutateAsync: changeWorkspacePostcodeDistrictsAPI,
    isLoading: isLoadingChangeWorkspacePostcodeDistricts,
    isSuccess: isSuccessChangeWorkspacePostcodeDistricts,
  } = useChangeWorkspaceDistricts();

  const { workspaceDistricts, refetchWorkspaceDistricts } = useWorkspacePostcodeDistricts({
    workspaceId: Number(workspaceId),
  });
  const { postcodeDistricts } = usePostcodeDistricts();

  const columns = getColumns({
    onToggleIsActive: (
      row: Row<ArrayElement<UseWorkspacePostcodeDistricts["workspaceDistricts"]>>,
      isActive: boolean
    ) => {
      changeWorkspacePostcodeDistrictsAPI({
        workspaceId: Number(workspaceId),
        workspaceDistrictsToBeAdded: [],
        workspaceDistrictIdsToBeRemoved: [],
        workspaceDistrictIdsToBeActivated: isActive ? [row.original.id] : [],
        workspaceDistrictIdsToBeDeactivated: !isActive ? [row.original.id] : [],
      });
    },
    onRemove: (row: Row<ArrayElement<UseWorkspacePostcodeDistricts["workspaceDistricts"]>>) => {
      changeWorkspacePostcodeDistrictsAPI({
        workspaceId: Number(workspaceId),
        workspaceDistrictsToBeAdded: [],
        workspaceDistrictIdsToBeRemoved: [row.original.id],
        workspaceDistrictIdsToBeActivated: [],
        workspaceDistrictIdsToBeDeactivated: [],
      });
    },
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<CreateWorkspacePostcodeDistrictSchema>({
    resolver: yupResolver(createWorkspacePostcodeDistrictSchema),
    defaultValues: {
      isActive: true,
    },
  });

  useEffect(() => {
    if (isSuccessChangeWorkspacePostcodeDistricts) {
      reset(undefined);
      refetchWorkspaceDistricts();
      setIsDialogOpen(false);
    }
  }, [isSuccessChangeWorkspacePostcodeDistricts]);

  const addWorkspacePostcodeDistrict = async (data: CreateWorkspacePostcodeDistrictSchema) => {
    await changeWorkspacePostcodeDistrictsAPI({
      workspaceId: Number(workspaceId),
      workspaceDistrictsToBeAdded: [
        {
          postcodeDistrictId: data.postcodeDistrictId,
          isActive: data.isActive,
        },
      ],
      workspaceDistrictIdsToBeRemoved: [],
      workspaceDistrictIdsToBeActivated: [],
      workspaceDistrictIdsToBeDeactivated: [],
    });
  };

  return (
    <>
      <section aria-labelledby="existing-users">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <h3
              id="existing-users"
              className="scroll-mt-10 font-semibold text-gray-900 dark:text-gray-50"
            >
              Postcode Districts
            </h3>
            <p className="text-sm leading-6 text-gray-500">
              You can add and manage postcode districts for this workspace.
            </p>
          </div>
          <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <DialogTrigger asChild>
              <Button className="mt-4 w-full gap-2 sm:mt-0 sm:w-fit">
                <RiAddLine className="-ml-1 size-4 shrink-0" aria-hidden="true" />
                Add Postcode District
              </Button>
            </DialogTrigger>

            <DialogContent className="sm:max-w-lg max-h-screen sm:max-h-[90vh]">
              <DialogHeader>
                <DialogTitle>Add a Postcode District to the workspace</DialogTitle>
                <DialogDescription className="mt-1 text-sm leading-6">
                  Channels are external platforms where agents can send messages to leads.
                </DialogDescription>
              </DialogHeader>
              <form
                onSubmit={handleSubmit(addWorkspacePostcodeDistrict)}
                className="mt-4  space-y-4"
              >
                <div>
                  <Label htmlFor="role-new-user" className="font-medium">
                    Code
                  </Label>
                  <Controller
                    control={control}
                    name="postcodeDistrictId"
                    render={({ field }) => {
                      return (
                        <Select
                          onValueChange={(value: string) => {
                            field.onChange(Number(value));
                          }}
                          {...field}
                          value={field.value?.toString()}
                        >
                          <SelectTrigger id="type" name="type" className="mt-2">
                            <SelectValue />
                          </SelectTrigger>
                          <SelectContent align="end">
                            {postcodeDistricts.map(postcodeDistrict => (
                              <SelectItem
                                key={postcodeDistrict.id}
                                value={postcodeDistrict.id?.toString()}
                              >
                                {postcodeDistrict.code}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      );
                    }}
                  ></Controller>
                  {errors.postcodeDistrictId && (
                    <ErrorMessage>{errors.postcodeDistrictId.message}</ErrorMessage>
                  )}
                </div>

                <div>
                  <div className="flex mt-5 items-center gap-2">
                    <Controller
                      name="isActive"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Switch id="isActive" checked={value} onCheckedChange={onChange} />
                      )}
                    />

                    <Label className="font-medium" htmlFor="isActive">
                      Activate Postcode District
                    </Label>
                  </div>

                  {errors.isActive && <ErrorMessage>{errors.isActive.message}</ErrorMessage>}
                </div>

                <DialogFooter className="mt-6">
                  <DialogClose asChild>
                    <Button
                      type="button"
                      className="mt-2 w-full sm:mt-0 sm:w-fit"
                      variant="secondary"
                    >
                      Go back
                    </Button>
                  </DialogClose>
                  <Button
                    type="submit"
                    disabled={isLoadingChangeWorkspacePostcodeDistricts}
                    className="w-full sm:w-fit"
                  >
                    Add Postcode District
                  </Button>
                </DialogFooter>
              </form>
            </DialogContent>
          </Dialog>
        </div>
        <div className="mt-4 sm:mt-6 lg:mt-10">
          <DataTable data={workspaceDistricts} columns={columns} />
        </div>
      </section>
    </>
  );
}
