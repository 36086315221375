import { ColumnSort } from "@tanstack/react-table";

export const translateSortingQuery = ({ sorting }: { sorting: ColumnSort[] }) => {
  if (sorting.length === 0) {
    return [
      {
        id: "desc",
      },
    ];
  }

  return sorting.map(sort => {
    const fields = sort.id.split(".");

    const sortOrder = sort.desc ? "desc_nulls_last" : "asc_nulls_first";

    if (fields.length > 1) {
      let result = {};
      let current: any = result;
      for (let i = 0; i < fields.length - 1; i++) {
        current[fields[i]] = {};
        current = current[fields[i]];
      }
      current[fields[fields.length - 1]] = sortOrder;
      return result;
    }

    return {
      [sort.id]: sortOrder,
    };
  });
};
