import { gql } from "@apollo/client";

export const getActiveWorkspacePostcodeDistricts = gql`
  query getActiveWorkspacePostcodeDistricts(
    $orderBy: [workspace_district_order_by!] 
    $workspaceId: Int!
  ) { 
    workspace_district(
      order_by: $orderBy
      where: { workspace_id: { _eq: $workspaceId }, is_active: { _eq: true } }
    ) {
      postcode_district {
        id
        code
      }
    }
  }
`;
