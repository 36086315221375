import { useEffect, useState } from "react";
import { useProspectedCampaignLeadProfiles } from "../../../../shared/domains/campaigns/graphql/hooks/useProspectedCampaignLeadProfiles";
import { getColumns } from "../components/Columns";
import { DataTable } from "../components/DataTable";
import { PAGE_SIZE } from "../data";
import { ColumnSort } from "@tanstack/react-table";
import { translateSortingQuery } from "../utils/index";
import { useCampaignLeadProfileById } from "../../../../shared/domains/campaigns/graphql/hooks/useCampaignLeadProfileById";
import { DataTableDrawer } from "../components/DataTableDrawer";
import { useTagCampaignLeadProfileAPI } from "../../../../shared/domains/campaigns/rest/tagCampaignLeadProfileAPI";
import { useSendMessageAPI } from "../../../../shared/domains/conversations/rest/sendMessageAPI";
import {
  useToggleReadStatusOnConversationThread,
  ToggleReadActionType,
  ToggleReadType,
} from "../../../../shared/domains/conversations/rest/toggleReadStatusAPI";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { useSaleActionStatuses } from "../../../../shared/domains/static/graphql/hooks/useSaleActionStatuses";
import { usePagination } from "../../../../shared/components/table/usePagination";
import { useDebounce } from "../../../../shared/hooks/UseDebounce";
import { useWorkspaces } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspaces";
import dayjs from "dayjs";
import {
  ACTION_DUE_DATE,
  ACTION_DUE_DATE_MAP,
  conversationValueMap,
  ConversationValueOption,
  INIFITY_FOR_MAX_CONVERSATION_VALUE,
} from "../data/data";

export const QUERY_PARAM_LEAD_PROFILE_ID = "lead-profile-id";

export default function Conversations() {
  const [sorting, setSorting] = useState<ColumnSort[]>([]);
  const [triggerFilter, setTriggerFilter] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [unreadOnly, setUnreadOnly] = useState<boolean>(false);

  const [lastMessageFilterDays, setLastMessageFilterDays] = useState<number>(0);
  const [outreachStatusActiveOption, setOutreachStatusActiveOption] = useState<number>(0);
  const [actionDueDate, setActionDueDate] = useState<number>(ACTION_DUE_DATE.ALL);
  const [conversationValueOption, setConversationValueOption] = useState<number>(
    ConversationValueOption.DEFAULT
  );
  const [saleActionStatusIds, setSaleActionStatusIds] = useState<number[]>([]);
  const [workspaceIds, setWorkspaceIds] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { workspaces } = useWorkspaces({
    orderBy: [{ name: "asc" }],
  });

  const { saleActionStatuses } = useSaleActionStatuses();

  useEffect(() => {
    if (workspaces.length > 0) {
      setWorkspaceIds(workspaces.map(workspace => workspace.id));
    }
  }, [workspaces]);

  useEffect(() => {
    if (saleActionStatuses.length > 0) {
      setSaleActionStatusIds(saleActionStatuses.map(status => status.id));
    }
  }, [saleActionStatuses]);

  const handleSearchNameChange = (needle: string) => {
    setSearchTerm(needle);
  };
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const onSaleActionStatusChange = (selectedIds: number[]) => {
    if (selectedIds.length === 0) {
      setSaleActionStatusIds(saleActionStatuses.map(status => status.id));
      setOffset(0);
      setSearchTerm("");
    } else {
      setSaleActionStatusIds(selectedIds);
      setOffset(0);
      setSearchTerm("");
    }
    setTriggerFilter(false);
  };

  const handleActionDueDateChange = (value: number) => {
    setActionDueDate(value);
    setOffset(0);
  };

  const handleLastReplyFilterChange = (value: number) => {
    setLastMessageFilterDays(value);
    setOffset(0);
  };

  const handleOutreachStatusChange = (value: number) => {
    setOutreachStatusActiveOption(value);
    setOffset(0);
  };

  const handleConversationValueFilterChange = (value: number) => {
    setConversationValueOption(value);
    setOffset(0);
    setTriggerFilter(false);
  };

  const handleUnreadOnlyChange = () => {
    setUnreadOnly(!unreadOnly);
    setOffset(0);
    setTriggerFilter(false);
  };

  const onWorkspacesChange = (selectedIds: number[]) => {
    if (selectedIds.length === 0) {
      setWorkspaceIds(workspaces.map(workspace => workspace.id));
      setOffset(0);
    } else {
      setWorkspaceIds(selectedIds);
      setOffset(0);
    }
  };
  const columns = getColumns({
    onEditClick: async row => {
      searchParams.set(QUERY_PARAM_LEAD_PROFILE_ID, String(row.original.id));
      setSearchParams(searchParams);
    },
  });

  const currentCampaignLeadProfileId = searchParams.get(QUERY_PARAM_LEAD_PROFILE_ID)
    ? Number(searchParams.get(QUERY_PARAM_LEAD_PROFILE_ID))
    : null;

  useEffect(() => {
    const load = async () => {
      await fetchCampaignLeadProfileById({ variables: { id: currentCampaignLeadProfileId } });
    };

    if (currentCampaignLeadProfileId) {
      load();
    }
  }, [searchParams]);

  const {
    fetchProspectedCampaignLeadProfiles,
    prospectedCampaignLeadProfiles,
    prospectedCampaignLeadProfilesCount,
  } = useProspectedCampaignLeadProfiles();

  const { offset, setOffset } = usePagination({
    pageSize: PAGE_SIZE,
    totalRecords: prospectedCampaignLeadProfilesCount,
  });

  const { campaignLeadProfile, fetchCampaignLeadProfileById } = useCampaignLeadProfileById();

  const {
    mutateAsync: tagCampaignLeadProfileMutation,
    isSuccess: tagCampaignLeadProfileMutationSuccess,
    isLoading: tagCampaignLeadProfileMutationLoading,
  } = useTagCampaignLeadProfileAPI();

  const { mutateAsync: sendMessageMutation, isSuccess: sendMessageMutationSuccess } =
    useSendMessageAPI();

  const { mutateAsync: markAsReadMutation, isSuccess: marAsReadSuccess } =
    useToggleReadStatusOnConversationThread({
      showToasts: false,
    });

  const { mutateAsync: markAsUnreadMutation, isSuccess: marAsUnReadSuccess } =
    useToggleReadStatusOnConversationThread({
      showToasts: true,
    });

  const conversationValueMin = conversationValueMap[conversationValueOption]?.min ?? 0;
  const conversationValueMax =
    conversationValueMap[conversationValueOption]?.max ?? INIFITY_FOR_MAX_CONVERSATION_VALUE;

  const lastReplyDaysAgoFilter =
    lastMessageFilterDays === 0
      ? {}
      : { _gte: dayjs().subtract(lastMessageFilterDays, "day").format("YYYY-MM-DD") };

  // if -1 do is null

  const outreachStatusFilter =
    outreachStatusActiveOption === -1
      ? { _is_null: true }
      : outreachStatusActiveOption === 0
      ? {}
      : { _eq: outreachStatusActiveOption };

  const actionDueDateFilter = ACTION_DUE_DATE_MAP[actionDueDate]
    ? {
        _lte: ACTION_DUE_DATE_MAP[actionDueDate].max,
        _gt: ACTION_DUE_DATE_MAP[actionDueDate].min,
      }
    : {};

  const adminSeenAtFilter = unreadOnly ? { _is_null: true } : {};
  useEffect(() => {
    fetchProspectedCampaignLeadProfiles({
      variables: {
        limit: PAGE_SIZE,
        offset: offset,
        orderBy: translateSortingQuery({ sorting }),
        saleActionStatusIds: saleActionStatusIds,
        nameFilter: debouncedSearchTerm ? `%${debouncedSearchTerm}%` : "%%",
        workspacesIds: workspaceIds,
        lastReplyFilter: lastReplyDaysAgoFilter,
        outreachStatusFilter: outreachStatusFilter,
        conversationValueMin,
        conversationValueMax,
        actionDueDateFilter,
        adminSeenAtFilter,
      },
    });
    if (campaignLeadProfile?.id) {
      fetchCampaignLeadProfileById({ variables: { id: campaignLeadProfile?.id } });
    }
  }, [
    unreadOnly,
    lastMessageFilterDays,
    outreachStatusActiveOption,
    saleActionStatusIds,
    workspaceIds,
    tagCampaignLeadProfileMutationSuccess,
    marAsReadSuccess,
    marAsUnReadSuccess,
    sendMessageMutationSuccess,
    conversationValueMax,
    conversationValueMin,
    offset,
    sorting,
    debouncedSearchTerm,
    actionDueDate,
  ]);

  const handleOnPaginationChange = ({ offset }: { offset: number; limit: number }) => {
    setOffset(offset);
  };

  const resetFilters = () => {
    setSaleActionStatusIds(saleActionStatuses.map(status => status.id));
    setWorkspaceIds(workspaces.map(workspace => workspace.id));
    setLastMessageFilterDays(0);
    setConversationValueOption(0);
    setOutreachStatusActiveOption(0);
    setSearchTerm("");
    setOffset(0);
    setTriggerFilter(true);
    setActionDueDate(ACTION_DUE_DATE.ALL);
    setUnreadOnly(false);
  };

  const isFiltered =
    saleActionStatusIds.length !== saleActionStatuses.length ||
    workspaceIds.length !== workspaces.length ||
    lastMessageFilterDays !== 0 ||
    conversationValueOption !== 0 ||
    debouncedSearchTerm !== "" ||
    actionDueDate !== 0 ||
    outreachStatusActiveOption !== 0 ||
    unreadOnly;

  const sendMessage = async (message: string) => {
    if (!campaignLeadProfile?.external_id) {
      toast.error("Cannot send message - Unable to Link to Conversation in Provider");
      return;
    }
    const payload = {
      campaignLeadProfileExternalId: campaignLeadProfile?.external_id,
      message,
    };
    await sendMessageMutation(payload);
  };

  const markAsRead = (threadId: number) => {
    if (!threadId) {
      toast.error("Error: Cannot mark conversation thread as read");
      return;
    }
    markAsReadMutation({
      conversationThreadId: threadId,
      action: ToggleReadActionType.READ,
      type: ToggleReadType.ADMIN,
    });
  };

  const markAsUnread = (threadId: number) => {
    if (!threadId) {
      toast.error("Error: Cannot mark conversation thread as unread");
      return;
    }
    markAsUnreadMutation({
      conversationThreadId: threadId,
      action: ToggleReadActionType.UNREAD,
      type: ToggleReadType.ADMIN,
    });
  };

  const saleActionStatusesOptions = saleActionStatuses.map(status => ({
    id: status.id,
    name: status.name,
  }));

  const workspaceOptions = workspaces.map((workspace: any) => ({
    id: workspace.id,
    name: workspace.name,
  }));

  const closeDrawer = () => {
    searchParams.delete(QUERY_PARAM_LEAD_PROFILE_ID);
    setSearchParams(searchParams);
  };

  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-lg font-semibold text-gray-900 sm:text-xl dark:text-gray-50">
            Conversations
          </h1>{" "}
          <p className="mt-2 text-sm text-gray-700">
            View and manage all your conversations in one place.
          </p>
        </div>
      </div>
      <div className="mt-4 sm:mt-6 lg:mt-10">
        <DataTable
          handleUnreadOnlyChange={handleUnreadOnlyChange}
          unreadOnly={unreadOnly}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchNameChange}
          isFiltered={isFiltered}
          handleActionDueDateChange={handleActionDueDateChange}
          onSaleActionStatusChange={onSaleActionStatusChange}
          saleActionStatusesOptions={saleActionStatusesOptions}
          workspaceOptions={workspaceOptions}
          onWorkspaceChange={onWorkspacesChange}
          data={prospectedCampaignLeadProfiles}
          columns={columns}
          totalRecords={prospectedCampaignLeadProfilesCount}
          offset={offset}
          resetFilters={resetFilters}
          setSorting={setSorting}
          triggerFilter={triggerFilter}
          onLastReplyFilterChange={handleLastReplyFilterChange}
          onOutreachStatusFilterChange={handleOutreachStatusChange}
          onConversationValueFilterChange={handleConversationValueFilterChange}
          sorting={sorting}
          onPaginationChange={handleOnPaginationChange}
        />
        {campaignLeadProfile && (
          <DataTableDrawer
            tagCampaignLeadProfileMutation={tagCampaignLeadProfileMutation}
            sendMessage={sendMessage}
            markAsRead={markAsRead}
            markAsUnread={markAsUnread}
            tagCampaignLeadProfileMutationLoading={tagCampaignLeadProfileMutationLoading}
            // outreachAgentTypes={outreachAgentTypes}
            // outreachPlatforms={outreachPlatforms}
            // campaignExternalConnectionProviders={campaignExternalConnectionProviders}
            // updateOutreachAgentMutationLoading={updateOutreachAgentMutationLoading}
            // updateOutreachAgentOnSubmit={updateOutreachAgentOnSubmit}
            // updateOutreachAgentErrors={updateOutreachAgentErrors}
            // updateOutreachAgentControl={updateOutreachAgentControl}
            // updateOutreachAgentRegister={updateOutreachAgentRegister}
            // updateOutreachAgentHandleSubmit={updateOutreachAgentHandleSubmit}
            // updateOutreachAgentSetValue={updateOutreachAgentSetValue}
            open={!!currentCampaignLeadProfileId}
            onOpenChange={closeDrawer}
            data={campaignLeadProfile}
          />
        )}
      </div>
    </>
  );
}
