import { ColumnDef, createColumnHelper, Row } from "@tanstack/react-table";

import { Badge } from "../../../../shared/components/badge/Badge";
import { ArrayElement } from "../../../../shared/utils";
import { TableColumnHeader } from "../../../../shared/components/table/TableColumnHeader";
import { Button } from "../../../../shared/components/button/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../shared/components/dropdownMenu/DropdownMenu";
import { RiMore2Fill } from "@remixicon/react";
import { UseWorkspacePostcodeDistricts } from "../../../../shared/domains/workspaces/graphql/hooks/useWorkspacePostcodeDistricts";
import dayjs from "dayjs";

const columnHelper =
  createColumnHelper<ArrayElement<UseWorkspacePostcodeDistricts["workspaceDistricts"]>>();

export const getColumns = ({
  onToggleIsActive,
  onRemove,
}: {
  onToggleIsActive: (
    row: Row<ArrayElement<UseWorkspacePostcodeDistricts["workspaceDistricts"]>>,
    isActive: boolean
  ) => void;
  onRemove: (row: Row<ArrayElement<UseWorkspacePostcodeDistricts["workspaceDistricts"]>>) => void;
}) =>
  [
    columnHelper.accessor("id", {
      header: ({ column }) => <TableColumnHeader column={column} title="Id" />,
      enableSorting: true,
      enableHiding: false,
      meta: {
        displayName: "Id",
      },
    }),
    columnHelper.accessor("postcode_district_id", {
      header: ({ column }) => <TableColumnHeader column={column} title="Postcode District" />,
      enableSorting: true,
      enableHiding: false,
      cell: ({ row }) => {
        return row.original?.postcode_district?.code ?? "Not available";
      },
    }),
    columnHelper.accessor("is_active", {
      header: ({ column }) => <TableColumnHeader column={column} title="Status" />,
      enableSorting: true,
      meta: {
        displayName: "Status",
      },
      cell: ({ row }) => {
        const statusValue = row.getValue("is_active");
        const activeStatus = statusValue === true;
        return (
          <Badge variant={activeStatus ? "success" : "error"}>
            {activeStatus ? "Active" : "Inactive"}
          </Badge>
        );
      },
    }),
    columnHelper.accessor("postcode_district.listings_last_fetched_at", {
      header: ({ column }) => <TableColumnHeader column={column} title="Listings Last Fetched At" />,
      enableSorting: true,
      meta: {
        displayName: "Listings Last Fetched At",
      },
      cell: ({ row }) => {
        const listingsLastFetchedAt = row.original.postcode_district.listings_last_fetched_at;
        return listingsLastFetchedAt ? dayjs(listingsLastFetchedAt).format("D MMM YYYY HH:mm A") : "N/A";
      },
    }),
    columnHelper.display({
      id: "edit",
      header: "Edit",
      enableSorting: false,
      enableHiding: false,
      meta: {
        displayName: "Edit",
      },
      cell: ({ row }) => {
        const isActive = row.original.is_active === true;
        return (
          <div className="flex items-center gap-2">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <Button
                  variant="ghost"
                  className="group size-8 hover:border hover:border-gray-300 hover:bg-gray-50 data-[state=open]:border-gray-300 data-[state=open]:bg-gray-50 hover:dark:border-gray-700 hover:dark:bg-gray-900 data-[state=open]:dark:border-gray-700 data-[state=open]:dark:bg-gray-900"
                >
                  <RiMore2Fill
                    className="size-4 shrink-0 text-gray-500 group-hover:text-gray-700 group-hover:dark:text-gray-400"
                    aria-hidden="true"
                  />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-36  -mt-2">
                {isActive && (
                  <>
                    <DropdownMenuItem
                      onClick={() => onToggleIsActive(row, false)}
                      className="text-red-600 dark:text-red-500"
                    >
                      Deactivate
                    </DropdownMenuItem>
                  </>
                )}
                {!isActive && (
                  <DropdownMenuItem onClick={() => onToggleIsActive(row, true)}>
                    Activate
                  </DropdownMenuItem>
                )}
                <DropdownMenuItem
                  onClick={() => onRemove(row)}
                  className="text-red-600 dark:text-red-500"
                >
                  Remove
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    }),
  ] as ColumnDef<ArrayElement<UseWorkspacePostcodeDistricts["workspaceDistricts"]>>[];
