import { Disclosure } from "@headlessui/react";
import {
  InformationCircleIcon,
  MinusIcon,
  PlusIcon
} from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";

import {
  Control,
  Controller,
  DeepRequired,
  FieldErrorsImpl,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";

import Select from "react-select";
import ErrorMessage from "../../../../../shared/components/errorMessage/ErrorMessage";
import { UsePostcodeDistricts } from "../../../../../shared/domains/postcodes/graphql/hooks/usePostcodeDistricts";
import { useActiveWorkspacePostcodeDistricts } from "../../../../../shared/domains/workspaces/graphql/hooks/useActiveWorkspacePostcodeDistricts";
import { reactSelectStyling } from "../../../../../shared/styles";
import { daysOnMarketOptions, maximumAddresses, priceOptions } from "../data";
import { CommisionCampaignValues } from "../validation";

interface OnMarketFiltersProps {
  nextStep: () => Promise<void>;
  previousStep: () => Promise<void>;
  register: UseFormRegister<CommisionCampaignValues>;
  setValue: UseFormSetValue<CommisionCampaignValues>;
  getValues: UseFormGetValues<CommisionCampaignValues>;
  errors: FieldErrorsImpl<DeepRequired<CommisionCampaignValues>>;
  watch: UseFormWatch<CommisionCampaignValues>;
  control: Control<CommisionCampaignValues, any>;
  trigger: UseFormTrigger<CommisionCampaignValues>;
  currentStep: number;
  postcodeDistricts: UsePostcodeDistricts["postcodeDistricts"];
}

export default function OnMarketFilters({
  previousStep,
  watch,
  setValue,
  getValues,
  register,
  errors,
  currentStep,
  trigger,
  control,
  nextStep,

  postcodeDistricts,
}: OnMarketFiltersProps) {
  const filters = watch("filters");

  const { workspaceDistricts, workspaceDistrictsLoading } = useActiveWorkspacePostcodeDistricts({ workspaceId: getValues("workspace").value });

  useEffect(() => {
    const selectedPostcodeDistricts = getValues("postcodeDistricts");
    if (!selectedPostcodeDistricts && workspaceDistricts.length) {
      const preSelectedDistricts = workspaceDistricts.map(district => ({
        label: district.postcode_district.code,
        value: district.postcode_district.id,
      }));

      setValue("postcodeDistricts", preSelectedDistricts);
    }
  }, [workspaceDistricts, setValue, getValues]);
 
  const validateAndNext = async () => {
    const results = await trigger(["filters", "postcodeDistricts"]);
    if (results) {
      nextStep();
    }
  };

  return (
    <div className="bg-gray-50 pb-20  min-h-screen h-full flex-wrap flex flex-col items-center justify-center focus:outline-none">
      <div className="relative w-full mx-auto px-8 pt-10 pb-8 md:px-10 xl:px-12">
        <div className=" max-w-3xl mx-auto space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {currentStep}. Which addresses do you want to{" "}
              <span className="text-orange-500">target</span>.
            </h2>
            <div className="mt-5 flex items-center justify-center rounded-md bg-orange-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon className="h-5 w-5 text-orange-400" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-orange-700">
                    Please let us know the characteristics of the properties you want to go after
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-3xl mx-auto">
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="shadow sm:rounded-md">
              <div className="space-y-6 bg-white mt-5 px-4 py-5 sm:p-6">
                <div className="w-full">
                  {/* here*/}

                  <div>
                    <div className=" flex flex-row justify-between items-center">
                      <div className="flex flex-row justify-between items-center w-full ">
                        <div className="flex flex-col">
                          <label className="block mt-5 text-sm font-medium text-gray-700">
                            Postcode District
                          </label>
                          <span className="text-gray-500 text-xs">
                            Please select postcode districts for market addresses
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 flex flex-col rounded-md shadow-sm">
                      <div className="w-full ">
                        <Controller
                          name="postcodeDistricts"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              styles={reactSelectStyling}
                              maxMenuHeight={220}
                              options={postcodeDistricts.map(postcodeDistrict => {
                                return {
                                  label: postcodeDistrict.code,
                                  value: postcodeDistrict.id,
                                };
                              })}
                              isSearchable
                              isMulti
                              isDisabled={workspaceDistrictsLoading}
                              placeholder={workspaceDistrictsLoading ? "loading assigned postcode districts ..." : "Select Postcode District"}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mt-2">
                    <Disclosure as="div" className="border-b border-gray-200 py-6">
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between  py-3 text-sm text-gray-400 hover:text-gray-500">
                              <div className="flex-row truncate flex space-x-2">
                                <span className="font-medium text-gray-900">Price</span>

                                <span className=" inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
                                  {filters.price.min.label} - {filters.price.max.label}
                                </span>
                              </div>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                ) : (
                                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="filters.price.min"
                                  className="block text-sm font-normal leading-6 text-gray-800"
                                >
                                  Min
                                </label>
                                <div className="mt-2">
                                  <Controller
                                    name="filters.price.min"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        styles={reactSelectStyling}
                                        maxMenuHeight={220}
                                        options={priceOptions.map(option => {
                                          return {
                                            label: option.label,
                                            value: option.value,
                                          };
                                        })}
                                        isSearchable
                                        placeholder="Min"
                                      />
                                    )}
                                  />
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="filters.budget.max"
                                  className="block text-sm font-normal leading-6 text-gray-800"
                                >
                                  Max
                                </label>
                                <div className="mt-2">
                                  <Controller
                                    name="filters.price.max"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        styles={reactSelectStyling}
                                        maxMenuHeight={220}
                                        options={priceOptions.map(option => {
                                          return {
                                            label: option.label,
                                            value: option.value,
                                          };
                                        })}
                                        isSearchable
                                        placeholder="Max"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    <Disclosure as="div" className="border-b border-gray-200 py-6">
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between  py-3 text-sm text-gray-400 hover:text-gray-500">
                              <div className="flex-row truncate flex space-x-2">
                                <span className="font-medium text-gray-900">
                                  Maximum No. Addresses in Campaign
                                </span>

                                <span className=" inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
                                  {filters.maximumAddresses.label}
                                </span>
                              </div>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                ) : (
                                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="maximumAddresses"
                                  className="block text-sm font-normal leading-6 text-gray-800"
                                >
                                  Max
                                </label>
                                <div className="mt-2">
                                  <Controller
                                    name="filters.maximumAddresses"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        styles={reactSelectStyling}
                                        maxMenuHeight={220}
                                        options={maximumAddresses.map(option => {
                                          return {
                                            label: option.label,
                                            value: option.value,
                                          };
                                        })}
                                        isSearchable
                                        placeholder="Max"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    <Disclosure as="div" className="border-b border-gray-200 py-6">
                      {({ open }) => (
                        <>
                          <h3 className="-my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between  py-3 text-sm text-gray-400 hover:text-gray-500">
                              <div className="flex-row truncate flex space-x-2">
                                <span className="font-medium text-gray-900">Days on Market</span>

                                <span className=" inline-flex items-center rounded-md bg-orange-50 px-2 py-1 text-xs font-medium text-orange-700 ring-1 ring-inset ring-orange-600/20">
                                  {filters.daysOnMarket.min.label} -{" "}
                                  {filters.daysOnMarket.max.label}
                                </span>
                              </div>
                              <span className="ml-6 flex items-center">
                                {open ? (
                                  <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                ) : (
                                  <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                )}
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="filters.daysOnMarket.min"
                                  className="block text-sm font-normal leading-6 text-gray-800"
                                >
                                  Min
                                </label>
                                <div className="mt-2">
                                  <Controller
                                    name="filters.daysOnMarket.min"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        styles={reactSelectStyling}
                                        maxMenuHeight={220}
                                        options={daysOnMarketOptions.map(option => {
                                          return {
                                            label: option.label,
                                            value: option.value,
                                          };
                                        })}
                                        isSearchable
                                        placeholder="Min"
                                      />
                                    )}
                                  />
                                </div>
                              </div>

                              <div className="sm:col-span-3">
                                <label
                                  htmlFor="filters.budget.max"
                                  className="block text-sm font-normal leading-6 text-gray-800"
                                >
                                  Max
                                </label>
                                <div className="mt-2">
                                  <Controller
                                    name="filters.daysOnMarket.max"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        styles={reactSelectStyling}
                                        maxMenuHeight={220}
                                        options={daysOnMarketOptions.map(option => {
                                          return {
                                            label: option.label,
                                            value: option.value,
                                          };
                                        })}
                                        isSearchable
                                        placeholder="Max"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>

                  {errors.filters && (
                    <ErrorMessage>Please select at least one Postcode District</ErrorMessage>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="py-3 mt-4">
            <div className="flex space-x-6">
              <button
                type="button"
                onClick={() => previousStep()}
                className="bg-white w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                Back
              </button>
              <button
                type="button"
                onClick={() => validateAndNext()}
                className="w-full inline-flex justify-center rounded-md border border-transparent bg-orange-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
